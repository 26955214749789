<template>
<div class="scroll-y-con">
  <div class="top-title">
    <div>{{`订单ID: ${order_no} (${formatDetailOrderStatus(data.order_status)})`}}
      <template v-if="data.order_status == 1 && data.order_source == 10">
        <a-button type="link" @click="showPay=true">选择支付方式</a-button>
        <pay-tip />
      </template>
      <template v-if="isOtherPlatOrder">
        <a-button type="link" class="ml-4 p-0" 
          @click="openOtherPlatOrder">查看第三方订单</a-button>
      </template>
    </div>
    <div>
      <span class="text-sm">
        <span style="font-weight:400;">订单来源：</span>
        <template>{{formatOrderSource(data.order_source)}}</template>
        <template v-if="data.order_source==15"> - {{data.add_outlets_name}}</template>
        <!-- <template v-if="data.is_cash_delivery_order==2">（货到付款）</template> -->
        <!-- <span v-if="data.place_order_enterprise_name" class="ml-1" style="font-weight:400;">（大客户-{{data.place_order_enterprise_name}}）</span> -->
      </span>
      <a-button
        v-if="!onlyRead && [1,2,3].includes(data.order_status)"
        type="link" class="ml-4 p-0"
        @click="handlerCancel"
      >取消订单</a-button>
      <a-tag v-if="data.status == -1" class="ml-4 mr-0" color="red">订单已删除</a-tag>
    </div>
  </div>

  <div class="page-content">
    <div v-if="isInBlackList" class="mb-2" style="background:#eee; padding:10px 20px;">
      <div>
        <span style="color:#f00;">此用户是黑名单用户</span>
      </div>
      <div class="flex align-center mt-2">
        <span>备注说明: </span>
        <span class="ml-2">{{ blackListRemarks }}</span>
      </div>
    </div>
    
    <div>
      <a-row>
        <a-col :span="8">
          <span>{{data.order_source === 10 || data.order_source === 15 ? '下单人' : '昵称'}}: </span>
          <span class="ml-2">{{ data.place_order_name }}</span>
          <a-button v-if="data.member_id && !onlyRead" class="ml-2" style="padding:0;"
              type="link" @click="openUserDetail">查看用户</a-button>
        </a-col>
        <a-col :span="8">
          <span>{{data.order_source === 10 || data.order_source === 15 ? '下单手机号' : '绑定手机号'}}: </span>
          <span class="ml-2">{{ data.place_order_phone }}</span>
        </a-col>
        <a-col :span="8">
          <span>下单时间: </span>
          <span class="ml-2">{{ data.order_add_time }}</span>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col :span="20">
          <span>下单备注: </span>
          <span class="ml-2">{{ data.order_remarks }}</span>
        </a-col>
        <!-- <a-col :span="4" style="text-align:right;">
          <a-button v-if="!onlyRead" type="link" class="p-0" @click="isAddConpon=true">补偿优惠券</a-button>
          <span v-if="data.compensate_coupon_list && data.compensate_coupon_list.length">(已发<a-button type="link" class="p-0" 
            @click="isShowOptLog=true">{{data.compensate_coupon_list.length}}</a-button>张)</span>
        </a-col> -->
      </a-row>
      <a-row class="mt-3" v-if="data.customer_service_remarks">
        <a-col :span="24">
          <span>客服备注: </span>
          <span class="ml-2">{{ data.customer_service_remarks }}</span>
        </a-col>
      </a-row>
      <a-row class="mt-3">
        <a-col :span="24" class="flex">
          <span>内部备注:</span>
          <span v-if="onlyRead">{{ data.private_remarks }}</span>
          <div v-else class="ml-2" style="flex:1;">
            <a-textarea
                v-model="data.private_remarks"
                :auto-size="{ minRows: 1, maxRows: 8 }"></a-textarea>
            <a-button class="mt-2" type="primary" size="small" @click="savePrivateRemarks()">修改备注</a-button>
          </div>
        </a-col>
      </a-row>
      <a-divider />
      <a-row class="mt-3">
        <a-col :span="5">
          <span>订单金额: </span>
          <span>{{ data.order_amount }}元</span>
        </a-col>
        <a-col :span="5">
          <span>运费金额: </span>
          <span>{{ data.freight_amount }}元</span>
        </a-col>
        <!-- <a-col :span="6">
          <span style="width:110px">全场活动优惠:</span>
          <span>{{ data.all_event_offers_amount }}元</span>
        </a-col> -->
        <a-col :span="8">
          <span>支付状态: </span>
          <span v-if="data.pay_status == 2">
            已支付
            <span style="font-size:12px;color:#999;"
              >（{{ data.order_financial_account_name }}）</span>
          </span>
          <a-tag v-if="data.pay_status == 1" class="mr-0" color="red">未支付</a-tag>
          <!-- // test 支付方式没加呢 -->
          <span v-if="data.is_cash_delivery == 2">
            <template v-if="data.pay_type==10 && data.pay_subtype!=0"
                >（货到付款-{{getCODPayType(data.pay_subtype)}}）</template>
            <template v-else
                >（货到付款）</template>
          </span>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col :span="5">
          <span>订单价格: </span>
          <span>{{ data.goods_amount }}元</span>
        </a-col>
        <!-- <a-col :span="5">
          <span>会员折扣: </span>
          <span>{{ data.vip_discount_amount }}元</span>
        </a-col> -->
        <!-- <a-col :span="6">
          <span style="width:110px">优惠券优惠:</span>
          <span>{{ data.coupon_offers_amount }}元</span>
        </a-col> -->
        <a-col :span="5">
        </a-col>
        <a-col :span="8">
          <span>支付时间: </span>
          <span>{{ data.pay_time }}</span>
        </a-col>
      </a-row>

      <div class="mt-3">
        <base-table :columnsData="pay_column" :customHeight="100" :tableData="payData" rowKey="key">
          <!-- <template #cash_delivery_amount="{record}">
            <span v-if="data.pay_status == 2 && data.pay_type == 10"
              >{{getCODPayType(data.pay_subtype)}} {{record.cash_delivery_amount}}</span>
            <span v-else>{{record.cash_delivery_amount}}</span>
          </template> -->
        </base-table>
      </div>

      <a-divider />

      <a-collapse :default-active-key="['1', '2']">
        <a-collapse-panel
          :show-arrow="false"
          key="1"
          v-if="outletOrder && outletOrder.suborder_no"
          :header="`【${outletOrder.suborder_type === 1 ? '自提' : '配送'}】子订单: ${outletOrder.suborder_no}(${outletOrder.suborder_statusname})`"
        >
          <template slot="extra">
            <a v-if="data.order_status !=-1 && outletOrder.suborder_type == 2 && outletOrder.suborder_status >= 5"
              class="pt-4 pb-4"
              type="link"
              @click.stop="handlerQuery(outletOrder.suborder_type)"
            >配送进度</a>
          </template>
          <a-row class="mt-3">
            <a-col :span="6">
              <span class="order-detail-title">取货方式: </span>
              <span>{{ outletOrder.suborder_type === 1 ? '自提' : '配送' }}</span>
            </a-col>
            <a-col :span="7">
              <span class="order-detail-title">负责网点: </span>
              <span>{{ outletOrder.outlets_name }}</span>
            </a-col>
            <a-col :span="11">
              <span>收货时间: </span>
              <span
                v-if="outletOrder.suborder_type === 1"
              >{{ formatTime(outletOrder.pick_up_info.pick_up_goods_time_start) }}-{{ formatTime(outletOrder.pick_up_info.pick_up_goods_time_end.split(' ')[1]) }}</span>
              <span
                v-else-if="outletOrder.suborder_type === 2"
              >{{ formatTime(outletOrder.delivery_info.delivery_time_start) }}-{{ formatTime(outletOrder.delivery_info.delivery_time_end.split(' ')[1]) }}</span>
            </a-col>
          </a-row>

          <!--如果是自提订单 显示这些-->
          <template v-if="outletOrder.suborder_type === 1">
            <a-row class="mt-3">
              <a-col :span="6">
                <span class="order-detail-title">取货人: </span>
                <span>{{ outletOrder.pick_up_info.recipient_name }}</span>
              </a-col>
              <a-col :span="7">
                <span class="order-detail-title">手机号: </span>
                <span>{{ outletOrder.pick_up_info.recipient_phone }}</span>
              </a-col>
              <a-col :span="11"></a-col>
            </a-row>
          </template>
          <!--如果是配送订单 显示这些-->
          <template v-if="outletOrder.suborder_type === 2">
            <a-row class="mt-3" v-if="outletOrder.delivery_info.contacts_phone">
              <a-col class="flex items-center" :span="6">
                <span class="order-detail-title">联系人: </span>
                <span>{{ outletOrder.delivery_info.contacts_name }}</span>
              </a-col>
              <a-col class="flex items-center" :span="7">
                <span class="order-detail-title">联系电话: </span>
                <span>{{ outletOrder.delivery_info.contacts_phone }}</span>
              </a-col>
              <a-col :span="11"></a-col>
            </a-row>

            <a-row class="mt-3">
              <a-col :span="6">
                <span class="order-detail-title">收货人: </span>
                <span>{{ outletOrder.delivery_info.consignee_name }}</span>
              </a-col>
              <a-col :span="7">
                <span class="order-detail-title">手机号: </span>
                <span>{{ outletOrder.delivery_info.consignee_phone }}</span>
              </a-col>
              <a-col :span="11">
                <span>收件地址: </span>
                <span>{{ outletOrder.delivery_info.consignee_address }}</span>
              </a-col>
            </a-row>
          </template>

          <div class="flex justify-between mt-4 mb-4">
            <span class="text-sm font-bold text-black">购买商品</span>
          </div>
          <base-table
            class="shop-table-class"
            ref="orderDetilTableRef"
            id="orderDetilTableID"
            :columnsData="detail_columns"
            rowKey="sku_id"
            :customHeight="400"
            :tableData="pdOrderGoodsList"
          >
            <template #is_refund="{ text }">
              <a-tag v-if="text==2" class="mr-0" color="red">已退费</a-tag>
              <!-- <a-tag v-if="text==1" class="mr-0" color="red">退费中</a-tag> -->
            </template>
          </base-table>

          <div
            class="mt-2 justify-between"
            v-if="outletOrder.cake_accessory_list && outletOrder.cake_accessory_list.length > 0"
          >
            <div>
              <span class="text-sm font-bold text-black">蛋糕配件</span>
            </div>
            <div v-for="(item,i) in outletOrder.cake_accessory_list" :key="i">
              <div class="flex mt-2">
                <div>{{ item.goods_name }}
                  <!-- <span class="ml-2" style="color:#aaa;">{{getSkuInfo(item.order_goods_id)['flavor_name']}} / {{getSkuInfo(item.order_goods_id)['specification_name']}}</span> -->
                  <span class="ml-2" style="color:#aaa;">{{getCakeSkuInfo(item.order_goods_id)['specification_name']}}</span>
                </div>
                <div class="ml-10">蜡烛: {{ formatBirthdayCandle(item.birthday_candle) }}</div>
                <div class="ml-10">餐具: {{ item.diners_number }}</div>
                <div class="ml-10">一次性餐具: {{item.plastic_tableware_number}}</div>
                <div class="ml-10">生日牌: {{ item.birthday_card }}</div>
                <div class="ml-10">贺卡: <p class="pre">{{ item.birthday_greeting_card }}</p></div>
              </div>
            </div>
          </div>
          <template v-if="giftList.length > 0">
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">赠品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="gift_columns"
              rowKey="sku_id"
              :tableData="giftList"
            ></base-table>
          </template>
          <template v-if="conponList.length > 0">
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">赠券</span>
            </div>
            <base-table
              class="shop-table-class mt-2"
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="conpon_columns"
              row-key="id"
              :tableData="conponList"
            ></base-table>
          </template>
        </a-collapse-panel>

        <a-collapse-panel
          key="2"
          v-if="isHaveExpress || splitGoodsList.length > 0"
          :header="`【快递】子订单：${expressOrder.suborder_no ? expressOrder.suborder_no : '新拆子订单'}(${expressOrder.suborder_statusname || ''})`"
          :show-arrow="false"
        >
          <template slot="extra">
            <a v-if="data.order_status !=-1 && expressOrder.suborder_status >= 2"
              class="pt-4 pb-4"
              type="link"
              @click.stop="handlerQuery(3)"
            >快递进度</a>
          </template>
          <!--如果是快递订单 显示这些-->
          <!--如果是拆单拆出来的快递单，显示这里-->
          <template>
            <a-row class="mt-3">
              <a-col class="flex items-center" :span="8">
                <span class="order-detail-title">订单金额：</span>
                <span>{{ expressOrder.suborder_amount }}元</span>
              </a-col>
              <a-col class="flex items-center" :span="8">
                <span>取货方式：</span>
                <span>快递</span>
              </a-col>
            </a-row>
            <a-row class="mt-3">
              <a-col :span="8">
                <span class="order-detail-title">收货人：</span>
                <span>{{ expressOrder.express_info.consignee_name }}</span>
              </a-col>
              <a-col :span="8">
                <span class="order-detail-title">手机号：</span>
                <span>{{ expressOrder.express_info.consignee_phone }}</span>
              </a-col>
              <a-col :span="8">
                <span class="order-detail-title">收件地址：</span>
                <span>{{ expressOrder.express_info.consignee_address }}</span>
              </a-col>
            </a-row>

            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">快递商品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="detail_columns"
              :customHeight="300"
              rowKey="sku_id"
              class="mt-2"
              :tableData="splitGoodsList"
            ></base-table>
          </template>
          <template v-if="isHaveExpress">
            <template v-if="giftList.length > 0">
              <div class="flex justify-between mt-4">
                <span class="text-sm font-bold text-black">赠品</span>
              </div>
              <base-table
                ref="orderDetilTableRef"
                id="orderDetilTableID"
                :columnsData="gift_columns"
                rowKey="sku_id"
                :tableData="giftList"
              ></base-table>
            </template>
            <template v-if="conponList.length > 0">
              <div class="flex justify-between mt-4">
                <span class="text-sm font-bold text-black">赠券</span>
              </div>
              <base-table
                class="shop-table-class mt-2"
                ref="orderDetilTableRef"
                id="orderDetilTableID"
                :columnsData="conpon_columns"
                row-key="id"
                :tableData="conponList"
              ></base-table>
            </template>
          </template>

        </a-collapse-panel>
      </a-collapse>
    </div>
    
    <!-- 底部操作项 -->
    <div class="flex justify-between">
      <div>
        <a-button class="bottom-btn" type="link" @click="isShowFlow = true">查看流水</a-button>
        <a-button class="bottom-btn" type="link" @click="isShowOptLog = true">查看操作记录</a-button>
        <template>
          <template v-if="invoiceStatus==1" >
            <a-button v-if="onlyRead" class="bottom-btn" type="link" 
                @click="isShowDetailInvoice = true">发票未开</a-button>
            <a-button v-else class="bottom-btn" type="link" 
                @click="isShowEditInvoice = true">发票未开</a-button>
          </template>
          <a-button v-if="invoiceStatus==2" class="bottom-btn" type="link" 
              @click="isShowDetailInvoice = true">发票已开</a-button>
          <span v-if="invoiceStatus==-1" class="bottom-btn">未申请发票</span>
        </template>   
        <template v-if="!isOtherPlatOrder && data.order_status == 4">
          <template v-if="!data.refund_status">
            <template v-if="!data.refund_switch">
              <span>售后开关:未设置</span>
              <a-button v-if="!onlyRead" class="bottom-btn" type="link" @click="switchRefund(true)">(点击开启)</a-button>
            </template>
            <template v-if="data.refund_switch==1">
              <span>售后开关:已打开</span>
              <a-button v-if="!onlyRead" class="bottom-btn" type="link" @click="switchRefund(false)">(点击关闭)</a-button>
            </template>
            <template v-if="data.refund_switch==2">
              <span>售后开关:已关闭</span>
              <a-button v-if="!onlyRead" class="bottom-btn" type="link" @click="switchRefund(true)">(点击开启)</a-button>
            </template>
          </template>
          <!-- 售后退款 -->
          <template>
            <a-button v-if="!data.refund_status && !onlyRead" 
              class="bottom-btn" type="link" @click="isShowRefundApply = true">申请退款</a-button>
            <a-button v-if="data.refund_status == 40 && !onlyRead" 
              class="bottom-btn" type="link" @click="isShowRefundAudit = true">审核退款</a-button>
            <a-button v-if="data.refund_status > 40" 
              class="bottom-btn" type="link" @click="isShowRefundDetail = true">退款详情</a-button>
          </template>
        </template>
      </div>
      <div>
        <template>
          <a-button class="bottom-btn r" type="link" @click="isShowSmsLog = true">短信历史</a-button>
          <a-button class="bottom-btn r" type="link" @click="showSendSms">发短信</a-button>
        </template>
      </div>
    </div>
    
  </div>

  <!-- 售后 -->
  <RefundApply v-if="isShowRefundApply" :show.sync="isShowRefundApply" :data="refundApplyForm" />
  <RefundAudit v-if="isShowRefundAudit" :show.sync="isShowRefundAudit" 
      :id="data.refund_work_sheet_id" :orderNo="order_no" />
  <RefundDetail v-if="isShowRefundDetail" :show.sync="isShowRefundDetail" 
      :id="data.refund_work_sheet_id" :orderNo="order_no" />
  
  <!-- 取消弹窗（包含其他支付） -->
  <cancel-modal v-if="isShowCancel"
       :show.sync="isShowCancel"
       :payInfo="data"
       @ok="handlerCancelSure" />
  <!-- 支付弹窗 -->
  <pay-modal v-if="showPay" :show.sync="showPay"
       :orderId="order_no"
       :orderType="data.pick_up_delivery_order.suborder_type"
       :totalAmount="Number(data.order_amount)"
       @paystatus="getPayStatus"></pay-modal>
  <!-- 物流 -->
  <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
      :requestID="order_no" 
      :hasDelivery="query_delivery" 
      :hasExpress="query_express" />
  <!-- 弹窗：流水、操作记录 -->
  <flow-modal
    v-if="isShowFlow"
    :show.sync="isShowFlow"
    :orderId="order_no" />
  <opt-modal
    v-if="isShowOptLog"
    :show.sync="isShowOptLog"
    :orderId="order_no" />
  <order-invoice-detail v-if="isShowDetailInvoice" :show.sync="isShowDetailInvoice" :requestID="order_no"/>
  <order-invoice-edit v-if="isShowEditInvoice" :show.sync="isShowEditInvoice" :requestID="order_no"/>

  <!-- 弹窗：短信 -->
  <smslog-modal
    v-if="isShowSmsLog"
    :show.sync="isShowSmsLog"
    :orderId="order_no" />
  <sendsms-modal
    v-if="isShowSendSms"
    :show.sync="isShowSendSms"
    :orderId="order_no"
    :phoneList="smsPhoneList" />
    
</div>
</template>

<script>
import { 
  getAllOrderDetail,
  cancelCakeOrder,
  notCakeCancelOrder,
  getOrderInvoice,
  updatePrivateRemarks,
  updateRefundSwitch
} from "@/api/customer"
import { getUserBlackInfo } from "@/api/vip"
import { 
  subCODPayTypeList,
  formatOrderSource 
} from '@/utils/type'
import pageData from "./columns"
import {
  formatBirthdayCandle,
  formatDetailOrderStatus,
  formatPdSubOrderStatus,
  formatEpSubOrderStatus,
} from "@/utils/type"
import QueryWindow from "@/components/order/queryWindow"
import FlowModal from "@/components/order/flow-modal.vue"
import SmslogModal from "@/components/order/smslog-modal.vue"
import SendsmsModal from "@/components/order/sendsms-modal.vue"
import OptModal from "@/components/order/opt-modal.vue"
import OrderInvoiceEdit from './components/order-invoice-edit.vue'
import OrderInvoiceDetail from './components/order-invoice-detail.vue'
import CancelModal from '@/components/order/cancel-modal.vue'
import PayModal from '../order/components/pay-modal.vue'
import PayTip from "@/components/order/pay-tip.vue"

import RefundApply from "../refund/components/refund-apply"
import RefundAudit from "../refund/components/refund-audit"
import RefundDetail from "../refund/components/refund-detail"

export default {
  components: {
    QueryWindow,
    FlowModal,
    OptModal,
    OrderInvoiceDetail,
    OrderInvoiceEdit,
    CancelModal,
    PayModal,
    PayTip,
    SmslogModal,
    SendsmsModal,
    RefundApply,
    RefundAudit,
    RefundDetail
  },
  props: {
    orderNo: {
      type: String,
      default: "",
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      subCODPayTypeList,
      formatOrderSource,
      ...pageData,

      onlyRead: false,
      
      isHaveExpress: false,

      order_no: "",

      splitGoodsList: [],
      expressOrder: [],
      pdOrderGoodsList: [],
      payData: [],
      giftList: [],
      conponList: [],
      data: {},
      outletOrder: {},
      // 发票
      invoiceStatus: 0,
      isShowDetailInvoice: false,
      isShowEditInvoice: false,
      // 售后
      isShowRefundApply: false,
      isShowRefundAudit: false,
      isShowRefundDetail: false,
      refundApplyForm: {},

      isShowFlow: false,
      isShowOptLog: false,
      isShowQuery: false,
      query_delivery: false,
      query_express: false,

      isInDrawer: false,
      isShowCancel: false,
      showPay: false,
      isCakeOrder: true, // test 黑天鹅当前全是蛋糕订单
      
      isInBlackList: false,
      blackListRemarks: "",

      isOtherPlatOrder: false,

      isShowSmsLog: false,
      isShowSendSms: false,
      smsPhoneList: [],
    }
  },
  watch:{
    num(){
      this.initData()
    }
  },
  created() {
    if(this.$route.query.show){ // 如果是新开页
      this.onlyRead = true
    }
    
    if(this.orderNo){ // 如果是抽屉页
      this.isInDrawer = true
      this.order_no = this.orderNo
    }else if(this.$route.query.order_no){ // 如果是新开页
      this.order_no = this.$route.query.order_no
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    formatBirthdayCandle,
    formatDetailOrderStatus,

    async initData() {
      const { data, code } = await getAllOrderDetail({
        order_no: this.order_no,
      })
      if(code != 0) return;

      this.isOtherPlatOrder = data.order_source >= 20

      this.data = data
      this.data.order_status_show = formatDetailOrderStatus(data.order_status)

      if (!Array.isArray(data.express_order)) {
        this.isHaveExpress = true
        this.expressOrder = data.express_order
        this.splitGoodsList = data.express_order.goods_list
        this.expressOrder.suborder_statusname = formatEpSubOrderStatus(
          data.express_order.suborder_status
        )
      }
      if (!Array.isArray(data.pick_up_delivery_order)) {
        this.outletOrder = data.pick_up_delivery_order
        this.outletOrder.suborder_statusname = formatPdSubOrderStatus(
          data.pick_up_delivery_order.suborder_status
        )
      }

      this.$emit('getDetail', this.data)

      this.giftList = data.event_gift_goods_list || []
      this.conponList =
        data.event_gift_coupon_list.map((item, i) => {
          item.id = i
          return item
        }) || []
      this.pdOrderGoodsList = data.pick_up_delivery_order.goods_list
      // this.isCakeOrder = data.pick_up_delivery_order.goods_list.findIndex(sku=>{
      //   return sku.goods_type==3
      // }) > -1
      this.payData = [{
        key: "0",
        wx_pay_amount:                data.wx_pay_amount,
        vip_card_pay_amount:          data.vip_card_pay_amount,
        stored_value_card_pay_amount: data.stored_value_card_pay_amount,
        alipay_amount:                data.alipay_amount,
        other_pay_amount:             data.other_pay_amount,
        cash_delivery_amount:         data.cash_delivery_amount,
        on_account_amount:            data.on_account_amount,
      }]

      // 发票
      getOrderInvoice({ order_no: this.order_no }).then(res=>{
        if(res.data){
          this.invoiceStatus = res.data.audit_status || -1
        }
      })
      // 黑名单
      if(this.data.member_id && !this.onlyRead){
        getUserBlackInfo({ member_id: this.data.member_id }).then(res=>{
          if(res.code == 0){
            this.isInBlackList = res.data.blacklist_status==2
            this.blackListRemarks = res.data.blacklist_remarks || ""
          }
        })
      }

      // 售后申请的表单
      const suborder_list = []
      if(this.outletOrder){
        suborder_list.push(this.outletOrder)
      }
      if(this.isHaveExpress){
        suborder_list.push(this.expressOrder)
      }
      this.refundApplyForm = {
        order_no: data.order_no,
        suborder_list: suborder_list,
        order_amount: data.order_amount,
        // goods_amount: data.goods_amount,
        freight_amount: data.freight_amount,
        order_source: data.order_source,
      }
    },

    openUserDetail(){
      const newPage = this.$router.resolve({
        name: "memberDetail",
        query: { member_id: this.data.member_id },
      });
      window.open(newPage.href, "_blank");
    },

    getCODPayType(subpaytype){
      subpaytype = subpaytype || 0
      let item = this.subCODPayTypeList.find(el=>{
        if(subpaytype == el.id) return el
      })
      return item && item.name || "未知"
    },
    // 物流
    handlerQuery(subOrderType) {
      this.query_delivery = subOrderType==2
      this.query_express  = subOrderType==3
      this.isShowQuery = true
    },

    // 取消订单
    handlerCancel(){
      if(this.checkNotOtherPlatOrder()){
        this.isShowCancel = true
      }
    },
    handlerCancelSure(refundData){
      let params = {
        ...refundData,
        order_no: this.data.order_no
      }

      let cancelFun = this.isCakeOrder ? cancelCakeOrder : notCakeCancelOrder
      const _this = this
      cancelFun(params).then(res => {
        if (res.code === 0) {
          _this.isShowCancel = false
          _this.$message.success("已取消")
          _this.initData()
          _this.$emit('order-canceled')
        }
      })
    },

    getPayStatus(){
      this.initData()
      this.showPay = false
    },

    async savePrivateRemarks(){
      updatePrivateRemarks({
        order_no: this.data.order_no,
        private_remarks: this.data.private_remarks
      }).then(res => {
        if (res.code === 0) {
          this.$message.success("已修改")
        }
      })
    },

    switchRefund(bool){
      const refund_switch = bool?1:2
      updateRefundSwitch({
        order_no: this.data.order_no,
        refund_switch: refund_switch
      }).then(res => {
        if (res.code === 0) {
          this.data.refund_switch = refund_switch
          this.$message.success("已修改")
        }
      })
    },
    
    getCakeSkuInfo(order_goods_id){
      let sku = this.outletOrder.goods_list.find(el=>{
        return el.order_goods_id == order_goods_id
      })
      return sku || {}
    },

    formatTime(time){
      if(/\d{2}:\d{2}:\d{2}/.test(time)){
        return time.slice(0, -3)
      }
      return time
    },

    checkNotOtherPlatOrder(){
      if(this.isOtherPlatOrder){
        this.$message.info("第三方订单到母订单操作")
        return false
      }
      return true
    },
    openOtherPlatOrder(){
      const newPage = this.$router.resolve({
        name: "otherPlatOrderDetail",
        query: { other_order_id: this.data.other_order_id },
      });
      window.open(newPage.href, "_blank");
    },

    showSendSms(){
      let phoneList = []
      if(this.outletOrder){
        if(this.outletOrder.suborder_type == 1){
          phoneList.push({
            type: "取货人",
            name: this.outletOrder.pick_up_info.recipient_name,
            phone: this.outletOrder.pick_up_info.recipient_phone,
          })
        }
        if(this.outletOrder.suborder_type == 2){
          phoneList.push({
            type: "收货人",
            name: this.outletOrder.delivery_info.consignee_name,
            phone: this.outletOrder.delivery_info.consignee_phone,
          })
          if(this.outletOrder.delivery_info.contacts_phone){
            phoneList.push({
              type: "联系人",
              name: this.outletOrder.delivery_info.contacts_name,
              phone: this.outletOrder.delivery_info.contacts_phone,
            })
          }
        }
      }
      if(this.data.place_order_phone){
        if(this.data.order_source == 10 || this.data.order_source == 15){
          phoneList.push({
            type: "下单人",
            name: this.data.place_order_name,
            phone: this.data.place_order_phone,
          })
        }else{
          phoneList.push({
            type: "下单用户",
            name: this.data.place_order_name,
            phone: this.data.place_order_phone,
          })
        }
      }
      this.smsPhoneList = phoneList
      this.isShowSendSms = true
    },

  },
}
</script>

<style lang="less" scoped>
.order-detail-container {
  // margin: 20px;
  padding: 20px 20px 50px 20px;
  background: #ffffff;
}
.shop-table-class {
  // margin-left: -10px;
}
.order-detail-title {
  width: 70px;
  text-align: right;
  display: inline-block;
}

.bottom-btn{
  padding: 0;
  margin-right: 20px;
  &.r{
    margin-left: 20px;
    margin-right: 0;
  }
}

.pre{
  white-space: pre;
  display: inline-block;
  vertical-align: top;
}
</style>
