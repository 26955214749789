<template>
  <a-modal
    :visible.sync="show"
    :title="'订单支付（'+totalAmount+'元）'"
    destroyOnClose
    width="550px"
    @cancel="handlerCancel"
    :footer="null"
  >
  <div class="justify-center">
    <div style="width:100%;">
      <a-row>
        <a-col :span="24">
          <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="支付方式">
            <a-radio-group v-model="payType">
              <!-- <a-radio :value="2">微信</a-radio>
              <a-radio :value="3">支付宝</a-radio> -->
              <a-radio :value="20">科技公司挂账（400）</a-radio>
              <!-- <a-radio :value="10" v-if="orderType==2">货到付款刷黑天鹅卡</a-radio> -->
              <!-- <a-radio :value="10" :disabled="orderType!=2">货到付款</a-radio> -->
              <a-radio :value="10">货到付款</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>

      <!-- 其他支付 -->
      <template v-if="payType==20" class="mt-5">
        <a-row class="mt-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" 
              label="付款凭证" class="upload-class" style="margin-bottom:0;">
              <uploadImage type="30" multiple :value.sync="img_files" @getList="(list)=>{img_files = list}" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="支付流水号" style="margin-bottom:0;">
              <a-input v-model="artificial_pay_flow_no"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="备注" style="margin-bottom:0;">
              <a-input type="textareas" v-model="remarks"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </template>
      <!-- 货到付款 -->
      <!-- <div v-if="payType==10" class="mt-5">
        <a-row class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" 
              label="预期方式">
              <a-select v-model="subCODType" style="width:100%;">
                <a-select-option
                  v-for="item in subCODTypeOptions"
                  :key="item.id"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div> -->

      <!-- 线上支付 -->
      <!-- <div id="qrcode" ref="qrcode"></div> -->

      <div class="mt-5 mb-5 flex justify-center">
        <!-- <a-button type>取消</a-button> -->
        <a-button type="primary" @click="handlerPay">确定</a-button>
      </div>
    </div>
  </div>
    
  </a-modal>
</template>

<script>
import { subCODPayTypeList } from '@/utils/type'
import { setOrderPayType } from '@/api/order/create'
// import QRCode from 'qrcodejs2'
import uploadImage from "@/components/upload-image"

export default {
  components: { uploadImage },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
    orderType: {
      type: Number,
      default: 2,
    },
    totalAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      payType: 20,
      artificial_pay_flow_no: "",
      remarks: "",
      img_files: [],
      qrcode: null,
      subCODType: 3, // 货到付款刷黑天鹅卡
      subCODTypeOptions: [{ 
        id: 0, 
        name: "无" 
      }].concat(subCODPayTypeList),
    }
  },
  mounted() {
    // this.qrcodeScan();
  },
  methods: {
    // qrcodeScan() {//生成二维码
    //   this.$nextTick(() => {
    //     const qrdiv = document.getElementById('qrcode')
    //     this.qrcode = new QRCode(qrdiv, {
    //       width: 200,  // 二维码宽度 
    //       height: 200, // 二维码高度
    //       text: 'https://mp.csdn.net'
    //     })
    //   })
    // },
    handlerPay() {
      let _this = this
      let params = {
        order_no: this.orderId,
        pay_type: this.payType,
      }
      if(this.payType == 10){
        params.pay_subtype = this.subCODType
      }else if(this.payType == 20){
        Object.assign(params, {
          artificial_pay_flow_no: this.artificial_pay_flow_no,
          remarks:  this.remarks,
          attached_image_paths: this.img_files.join(",")
        })
      }

      // 提交
      if(this.payType == "20"){ // 其他收款
        this.$confirm({
          title: "确认已收款"+this.totalAmount+"元？",
          okText: "我确认已收款",
          cancelText: "取消",
          onOk() {
            setOrderPayType(params).then(res=>{
              if(res.code == "0"){
                _this.$message.success('操作成功')
                _this.$emit('paystatus')
                // _this.$emit("update:show", false)
              }
            })
          }
        })
      }else{ // 货到付款（目前只分 其他和货到付款）
        setOrderPayType(params).then(res=>{
          if(res.code == "0"){
            _this.$message.success('操作成功')
            _this.$emit('paystatus')
            // _this.$emit("update:show", false)
          }
        })
      }
    },

    handlerCancel(){
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>
</style>