<template>
  <div>
    <a-modal
      :visible.sync="show"
      width="70%"
      title="发票信息"
      @cancel="$emit('update:show', false)"
    >
      <div class="header">
        <h4>订单支付:</h4>
        <span v-if="Number(invoiceData.vip_card_pay_amount)>0">会员卡 {{ invoiceData.vip_card_pay_amount }}元</span>
        <span v-if="Number(invoiceData.stored_value_card_pay_amount)>0">储值卡 {{ invoiceData.stored_value_card_pay_amount }}元</span>
        <span v-if="Number(invoiceData.wx_pay_amount)>0">微信 {{ invoiceData.wx_pay_amount }}元</span>
        <span v-if="Number(invoiceData.alipay_amount)>0">支付宝 {{ invoiceData.alipay_amount }}元</span>
        <span v-if="Number(invoiceData.cash_delivery_amount)>0">货到付款 {{ invoiceData.cash_delivery_amount }}元</span>
      </div>

      <base-table
        ref="invoiceRef"
        rowKey="invoiceTableRowKey"
        :columnsData="columns_invoice"
        :tableData="invoiceTableData"
        :customHeight="200"
      >
        <template #type="{ text }">
          <span v-if="text == 1">软点</span>
          <span v-else-if="text == 2">西点</span>
          <span v-else>蛋糕</span>
        </template>
      </base-table>

      <!-- 旺店通账户 分仓库 -->
      <template v-if="expressTableData && expressTableData.length">
        <h5 class="mt-3" style="color:#f00;">* 此订单的旺店通子订单被拆分为多仓库发货，所以需要人工分仓库开票</h5>
        <base-table
          :columnsData="columns_express"
          :tableData="expressTableData"></base-table>
      </template>

      <div class="footer">
        <a-form-model
          layout="horizontal"
          :model="invoiceInfo"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="formRef"
          :rules="rules"
        >
          <a-row>
            <a-col :span="6">
              <a-form-model-item label="申请金额">{{ invoiceInfo.amount }} 元</a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                prop="email" label="邮箱">
                <a-input v-model="invoiceInfo.email"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 企业 -->
          <template v-if="invoiceInfo.type==1">
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="抬头类型">
                  <a-radio-group v-model="invoiceInfo.type">
                    <a-radio :value="1">企业</a-radio>
                    <a-radio :value="2">个人</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" label="抬头">
                  <a-input v-model="invoiceInfo.name"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" label="税号">
                  <a-input v-model="invoiceInfo.corporate_duty_paragraph"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="6">
                <a-form-model-item label="电话">
                  <a-input v-model="invoiceInfo.phone"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" 
                  label="开户行">
                  <a-input v-model="invoiceInfo.open_bank"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="账号">
                  <a-input v-model="invoiceInfo.bank_account"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item :labelCol="{span:2}" :wrapperCol="{span:22}" label="注册地址">
                  <a-input v-model="invoiceInfo.registered_address"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </template>

          <!-- 个人 -->
          <template v-if="invoiceInfo.type==2">
            <a-row>
              <a-col :span="6">
                <a-form-model-item label="抬头类型">
                  <a-radio-group v-model="invoiceInfo.type">
                    <a-radio :value="1">企业</a-radio>
                    <a-radio :value="2">个人</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="11">
                <a-form-model-item :labelCol="{span:3}" :wrapperCol="{span:21}" label="抬头">
                  <a-input v-model="invoiceInfo.name"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="7">
                <a-form-model-item :labelCol="{span:4}" :wrapperCol="{span:20}" 
                  label="电话" prop="phone">
                  <a-input v-model="invoiceInfo.phone"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </template>

          <!-- 上传发票 -->
          <!-- <a-row class="mt-2">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{span:22}" 
                label="上传发票" class="upload-class">
                <uploadImage type="20" multiple :value.sync="invoiceInfo.invoice_files" @getList="(list)=>{invoiceInfo.invoice_files = list}" />
              </a-form-model-item>
            </a-col>
          </a-row> -->
          <!-- <a-row class="mb-2">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{span:22}" label="开票备注">
                <a-input type="textareas" v-model="invoiceInfo.audit_remarks"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row> -->

        </a-form-model>
      </div>

      <template slot="footer">
        <a-button type="primary" key="submit" @click="handlerOK()">
          保存修改
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {columns_invoice} from "../../../trading-manage/order-invoice/columns.js"

// import uploadImage from "@/components/upload-image"
import {
  getOrderInvoice,
  updateOrderInvoice,
} from "@/api/customer.js"
export default {
  components: {  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    requestID: {
      type: [Number,String],
    },
    selectKeys: {
      type: Array,
    },
  },
  data() {
    // let phoneCheck = (rule, value, callback) => {
    //   const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    //   if (!reg.test(value)) {
    //     callback("手机号码格式有误")
    //     return
    //   } else {
    //     callback()
    //   }
    // }
    let emailCheck = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (!reg.test(value)) {
        callback("邮箱格式有误")
        return
      } else {
        callback()
      }
    }
    return {
      columns_invoice: columns_invoice,
      rules: {
        // phone: [{ validator: phoneCheck, trigger: "blur" }],
        email: [{ validator: emailCheck, trigger: "blur" }],
      },
      form: {},
      imgList: [],
      invoiceData: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      invoiceTableData: [],
      invoiceInfo: {},
      expressTableData: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { code, data } = await getOrderInvoice({ order_no: this.requestID })
      if (code == 0) {
        this.invoiceData = data
        this.invoiceTableData = data.goods_list
        this.invoiceTableData.map((item, index) => {
          item["invoiceTableRowKey"] = index
          return item
        })
        this.expressTableData = data.wdt_order_list
        this.invoiceInfo = data.invoice_info
      }
    },
    handlerOK() {
      const params = this.invoiceInfo

      // - type  int 抬头类型（1-企业，2-个人）
      // - name  string  公司/抬头名称 企业/个人
      // - phone string  电话 企业/个人
      // - email string  邮箱 企业/个人
      // - corporate_duty_paragraph  string  公司税号 企业
      // - registered_address  string  注册地址 企业
      // - open_bank string  开户银行 企业
      // - bank_account  string  银行账号 企业
      params.order_no = this.requestID
      updateOrderInvoice(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("已保存")
          this.$emit("update:show", false)
          this.$emit('ok')
        }
      })
    },
    handlerCancel() {
      this.$emit("update:show", false)
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  margin-bottom: 10px;
  h4,span{
    margin-right:30px;
  }
}
.footer {
  margin-top: 20px;
  .img-class {
    width: 150px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
  }
}
.ant-form-item {
  margin-bottom: 0;
}

/deep/ .upload-class .ant-upload-picture-card-wrapper{
  margin-top:10px;
}
</style>