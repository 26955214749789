<template>
  <div>
    <a-modal :visible.sync="show"
             width="960px"
             title="申请退款"
             :footer="null"
             @cancel="$emit('update:show',false)">

      <div class="footer" style="margin-top:0;">
        <a-form-model :model="formData"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="refundDetailRef">
          <!-- 申请内容 -->
          
          <a-row>
            <a-col :span="24">
              <a-form-model-item prop :labelCol="{ span: 2 }" label="退款理由">
                <a-select placeholder="顾客可见" 
                    v-model="formData.refund_reason">
                      <a-select-option v-for="(item, index) in REASON_LIST"
                          :key="index"
                          :value="item">{{item}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row style="margin-bottom: 10px;">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 2 }" label="图片说明">
                <uploadImage type="24" multiple :value.sync="imgList" @getList="(list)=>{imgList = list}" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 子订单 -->
          <div
            class="mb-3"
            v-for="(orderitem, index) in infoData.suborder_list"
            :key="orderitem.suborder_no"
          >
            <div class="flex justify-between mt-3 mb-2">
              <span
                class="text-sm font-bold text-black"
              >{{orderitem.order_type_name}}子订单 {{orderitem.suborder_no}}</span>
              <span
                v-if="Number(orderitem.freight_amount)>0"
                class="text-sm text-black"
              >运费{{orderitem.freight_amount}}元</span>
            </div>
            <base-table ref="shopRef"
                    :customHeight="300"
                    :columnsData="invoiceColumns"
                    rowKey="order_goods_id"
                    :row-selection="index==0 ? rowSelection1 : rowSelection2"
                    :tableData="orderitem.goods_list">
            </base-table>
          </div>
          
          <!-- 审核内容 -->
          <!-- 金额 待审核 -->
          <a-row v-if="infoData.suborder_list">
            <!-- 只有一个订单 -->
            <a-col :span="24" v-if="infoData.suborder_list.length < 2">
              <a-form-model-item :labelCol="{ span: 2 }" prop label="退款金额">
                <a-input-number v-if="infoData.suborder_list[0].order_type == 1" :min="0" :step="0.01" 
                  v-model="formData.audit_pick_up_order_refund_amount" 
                  @change="changeNumber"></a-input-number>
                <a-input-number v-if="infoData.suborder_list[0].order_type == 2" :min="0" :step="0.01" 
                  v-model="formData.audit_delivery_order_refund_amount" 
                  @change="changeNumber"></a-input-number>
                <a-input-number v-if="infoData.suborder_list[0].order_type == 3" :min="0" :step="0.01" 
                  v-model="formData.audit_express_order_refund_amount" 
                  @change="changeNumber"></a-input-number>
                元
                <span v-if="containsFreight">（含运费）</span>
              </a-form-model-item>
            </a-col>
            <!-- 多子订单 -->
            <a-col :span="24" v-else>
              <a-form-model-item :labelCol="{ span: 2 }" prop label="退款金额">
                <span v-for="orderitem in infoData.suborder_list" class="audit_amount_input"
                     :key="orderitem.suborder_no" style="display: inline-block;">
                  <template v-if="orderitem.order_type == 1">
                    自提子订单
                    <a-input-number :min="0" :step="0.01" 
                        v-model="formData.audit_pick_up_order_refund_amount" 
                        @change="changeNumber"></a-input-number>
                    <span class="ml-2 mr-2">+</span>
                  </template>
                  <template v-if="orderitem.order_type == 2">
                    配送子订单
                    <a-input-number :min="0" :step="0.01" 
                        v-model="formData.audit_delivery_order_refund_amount" 
                        @change="changeNumber"></a-input-number>
                    <span class="ml-2 mr-2">+</span>
                  </template>
                  <template v-if="orderitem.order_type == 3">
                    快递子订单
                    <a-input-number :min="0" :step="0.01" 
                        v-model="formData.audit_express_order_refund_amount" 
                        @change="changeNumber"></a-input-number>
                  </template>
                </span>
                <span class="ml-2 mr-2">=</span>
                <span class="font-bold">{{
                (
                  Number(formData.audit_pick_up_order_refund_amount) + 
                  Number(formData.audit_delivery_order_refund_amount) + 
                  Number(formData.audit_express_order_refund_amount)
                ).toFixed(2)}}元</span>
                <span v-if="containsFreight">（含运费）</span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 审核内容 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item prop :labelCol="{ span: 2 }" label="备注说明">
                <a-input 
                  placeholder="顾客不可见"
                  v-model="formData.audit_remarks"
                />
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-form-model>
      </div>

      <!-- 底部按钮 -->
      <div>
        <div class="flex items-center justify-center pt-3" style="border-top:1px solid lightgray;">
          <a-button @click="handlerCancel" type="info" class="mr-7">取消</a-button>
          <template v-if="needManual">
            <a-button type="primary" @click="handlerSave">提交</a-button>
            <span class="ml-2" style="color:#999;">*提交后需财务打款</span>
          </template>
          <a-button v-else type="primary" @click="handlerSave">提交（系统会自动退款）</a-button>
        </div>
      </div>
    </a-modal>

    <a-modal
      class="preview-class"
      title="退款图片"
      width="50%"
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>

import { applyRefund } from '@/api/refund.js'
import { formatGoodsType } from '@/utils/type.js'
import uploadImage from "@/components/upload-image"

const ORDER_TYPE = {
  1: "自提",
  2: "配送",
  3: "快递",
}

const REASON_LIST = [
  '商品错发、漏发', 
  '商品质量问题', 
  '快递一直未送达', 
  '未按约定时间发货', 
  '包装、商品破损',
  '其他',
]

export default {
  components: { uploadImage },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
    },
    data: {
      type: Object,
      default: null,
    },
    // 是初次审核还是二次
    // type: {
    //   type: String,
    //   default: 'audit'
    // }
  },
  data () {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },

      REASON_LIST,
      previewVisible: false,
      previewImage: "",
      invoiceColumns: [
        {
          title: "产品属性",
          dataIndex: "goods_type",
          align: "center",
          width: 90,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          width: "40%",
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          width: "30%",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
          width: "30%",
        },
        {
          title: "数量",
          dataIndex: "number",
          align: "center",
          width: "80px",
        },
        {
          title: "商品总价",
          dataIndex: "goods_price",
          align: "center",
          width: "80px",
        },
        {
          title: "支付金额",
          dataIndex: "order_price",
          align: "center",
          width: "80px",
        },
      ],
      
      infoData: {},
      imgList: [],
      formData: {
        order_no: "",
        refund_reason: undefined,
        attached_images_path: "",

        audit_pick_up_order_refund_amount: 0,
        audit_delivery_order_refund_amount: 0,
        audit_express_order_refund_amount: 0,

        audit_remarks: "",

        // 以下不需要提交
        apply_pick_up_order_refund_amount: 0,
        apply_delivery_order_refund_amount: 0,
        apply_express_order_refund_amount: 0,
      },
      containsFreight: false,
      
      needManual: false,

      // 1
      rows1: [],
      rowSelection1: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection1.selectedRowKeys.unshift(record.order_goods_id)
            this.rows1.push(record)
          } else {
            this.rowSelection1.selectedRowKeys = this.rowSelection1.selectedRowKeys.filter(
              (el) => el != record.order_goods_id
            )
            this.rows1 = this.rows1.filter((el) => el.order_goods_id != record.order_goods_id)
          }
          this.changeGoods()
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = this.selectedRows
          if(selected){
            this.rowSelection1.selectedRowKeys = selectedRows.map(el=>el.order_goods_id)
            this.rows1 = selectedRows
          }else{
            this.rowSelection1.selectedRowKeys = []
            this.rows1 = []
          }
          this.changeGoods()
        },
      },

      // 2
      rows2: [],
      rowSelection2: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection2.selectedRowKeys.unshift(record.order_goods_id)
            this.rows2.push(record)
          } else {
            this.rowSelection2.selectedRowKeys = this.rowSelection2.selectedRowKeys.filter(
              (el) => el != record.order_goods_id
            )
            this.rows2 = this.rows2.filter((el) => el.order_goods_id != record.order_goods_id)
          }

          this.changeGoods()
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = this.selectedRows
          if(selected){
            this.rowSelection2.selectedRowKeys = selectedRows.map(el=>el.order_goods_id)
            this.rows2 = selectedRows
          }else{
            this.rowSelection2.selectedRowKeys = []
            this.rows2 = []
          }
          this.changeGoods()

        },
      },

    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      if (this.data) {
        console.log(this.data)


        this.formData.order_no = this.data.order_no
        // 
        this.infoData = JSON.parse(JSON.stringify(this.data))        
        this.infoData.suborder_list.map((el) => {
          el.order_type_name = ORDER_TYPE[el.suborder_type]
          el.order_type = el.suborder_type
        })

        // 子订单 运费
        const delivery_order = this.infoData.suborder_list.find((suborder)=>{return suborder.order_type==2})
        const express_order = this.infoData.suborder_list.find((suborder)=>{return suborder.order_type==2})
        if(delivery_order){
          delivery_order.freight_amount = this.infoData.freight_amount
        }else if(express_order){
          express_order.freight_amount = this.infoData.freight_amount
        }
      }

      this.needManual = this.data.order_source >= 10
    },

    changeGoods(){
      let selectedIds = this.rowSelection1.selectedRowKeys.concat(this.rowSelection2.selectedRowKeys)

      let isAllSelect = true

      this.infoData.suborder_list.map((suborder)=>{
        let goodslist = suborder.goods_list.filter(good=>{
          return selectedIds.includes(good.order_goods_id)
        })

        if(goodslist.length != suborder.goods_list.length){
          isAllSelect = false
        }
        // 子订单的退款商品金额
        let amount = 0
        goodslist.map(el=>{
          amount += Number(el.order_price)
        })
        if(suborder.order_type == 1){
          this.formData.apply_pick_up_order_refund_amount = amount
        }else if(suborder.order_type == 2){
          this.formData.apply_delivery_order_refund_amount = amount
        }else if(suborder.order_type == 3){
          this.formData.apply_express_order_refund_amount = amount
        }
      })

      // 运费
      const freight_amount = Number(this.infoData.freight_amount)
      // 退全部商品的话 加运费
      this.containsFreight = isAllSelect && freight_amount>0
      if(this.containsFreight){
        if(this.formData.apply_delivery_order_refund_amount > 0){
          this.formData.apply_delivery_order_refund_amount += freight_amount
        }else if(this.formData.apply_express_order_refund_amount > 0){
          this.formData.apply_express_order_refund_amount += freight_amount
        }
      }

      // 重算子订单退款金额
      this.formData.audit_pick_up_order_refund_amount = this.formData.apply_pick_up_order_refund_amount.toFixed(2)
      this.formData.audit_delivery_order_refund_amount = this.formData.apply_delivery_order_refund_amount.toFixed(2)
      this.formData.audit_express_order_refund_amount = this.formData.apply_express_order_refund_amount.toFixed(2)

      this.formData.order_goods_ids = selectedIds.join(",")
    },
    changeNumber(){
      if(this.formData.apply_pick_up_order_refund_amount == 0){
        this.formData.audit_pick_up_order_refund_amount = 0
      }
      if(this.formData.apply_delivery_order_refund_amount == 0){
        this.formData.audit_delivery_order_refund_amount = 0
      }
      if(this.formData.apply_express_order_refund_amount == 0){
        this.formData.audit_express_order_refund_amount = 0
      }
      // 校验金额：退款不能超额
      const audit_amount = Number(this.formData.audit_pick_up_order_refund_amount) 
                        + Number(this.formData.audit_delivery_order_refund_amount) 
                        + Number(this.formData.audit_express_order_refund_amount)
      if(audit_amount > Number(this.infoData.order_amount)){
        this.$message.info('退款金额不能大于订单总额')
      }
    },
    handlerSave () {
      if(!this.formData.order_goods_ids){
        this.$message.info('请勾选退款商品')
        return false
      }
      const audit_amount = Number(this.formData.audit_pick_up_order_refund_amount) 
                        + Number(this.formData.audit_delivery_order_refund_amount) 
                        + Number(this.formData.audit_express_order_refund_amount)
      if(audit_amount > Number(this.infoData.order_amount)){
        this.$message.info('退款金额不能大于订单总额')
        return false
      }

      this.formData.attached_images_path = this.imgList.join(",")
      applyRefund(this.formData).then(res => {
        if (res.code === 0) {
          if(this.needManual){
            this.$message.success('已提交')
          }else{
            this.$message.success('已提交，系统会自动退款')
          }
          
          this.$parent.initData()
          this.$emit('update:show', false)
        }
      })
    },

    handlerCancel () {
      this.$emit('update:show', false)
    },

    handlerBigImg (e) {
      this.previewImage = e.image_url
      this.previewVisible = true
    },

  },
}
</script>

<style lang="less" scoped>
.audit_amount_input{
  // max-width:140px !important;
  .ant-input-number{
    width: 100px;
    max-width:120px;
  }
  .plus{
    margin: 0 5px;
  }
}
.footer {
  margin: 20px 0;
  .img-class {
    width: 100px;
    height: 75px;
    // object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>